@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

body {
    background-color: #e0e3e8;
    font-family: "Rubik", sans-serif;
}
.badge {
    background-color: red;
    border-radius: 50px;
    color: #fff;
    padding: 4px 10px;
    text-align: center;
  }
.cell{
    display: flex;
    align-items: center;
    flex-direction: row;
  }
/* ---Sidebar css */
.text-primary{
  color: #5a8dee!important;
}
.btn-primary {
    border-color: #5a8dee !important;
    background-color: #5a8dee !important;
    color: #fff;
}
.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
  background: linear-gradient(#fff8e6, #fff0cb) padding-box, linear-gradient(#ffb700, #feb7c8) border-box;
}
.sidebar{
  /* display: none; */
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background: linear-gradient(#fff8e6, #fff0cb) padding-box, linear-gradient(#ffb700, #feb7c8) border-box;
        position: fixed;
    top: 0;
    left: -300%;
    z-index: 1;
    transition: 0.5s;
}
.sidebar.active{
  /* display: block; */
    left: 0;
}
.sd-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}
.sd-body{
    padding: 15px;
    max-height: calc(100vh - 150px);
    margin:5px;
    border: 2px black solid ;
    overflow-x: hidden;
    border-radius: 5px;
}
.sd-body ul{
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
}
.sd-body ul li{
    list-style: none;
    margin-bottom: 8px;
}
.sd-body ul li .sd-link{
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}