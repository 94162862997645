.home {
  /* background: url('../../Assets/paw.svg'); */
  background-repeat: no-repeat;
  background-size: 20%;
}
/* .sclad_container{
  overflow-x: auto;
} */
.p {
  width: 90%;
  text-align: center;
  margin: 20px auto
}

.borders {
  flex-direction: column;
 
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-top: 35px;
  margin-right: 2%;
  margin-left: 2%;
    font-weight: bold;
    border: solid 10px transparent;
  border-radius: 30px;
  background:
    linear-gradient(#ffffff, #fff0cb) padding-box,
    linear-gradient(#ffb700, #feb7c8) border-box;
  align-items: center;

}

.container_marcas {
  /* background: url('../../Assets/paw.svg'); */
  background-repeat: no-repeat;
  background-size: 13%;
  background-position: right;
}
.testdd{
  width: 100%;
  display: flex;
  justify-content: center;
}
.shopslist{
  display: flex;
  width: 90%;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
}

.container_destaques {
  /* background: url('../../Assets/paw.svg'); */
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: left;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}
.sclad_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
.tableth {
  font-weight: bold;
  padding: 7px;
  background: var(--primary-color);
  border: none;
  text-align: left;
  font-size: 15px;
  border-top: 3px solid #fff;
  border-bottom: 3px solid var(--primary-color);
}
.tabletd {
  padding: 7px;
  border: none;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  font-size: 15px;
}
.table tbody tr:nth-child(even){
  background: #f8f8f8!important;
}
.table2{
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  border-radius: 30px;
}
.badge {
  background-color: red;
  border-radius: 50px;
  color: #fff;
  padding: 4px 10px;
  text-align: center;
}
  .table {
   
      border: 5px solid #fff;
      border-top: 5px solid #fff;
      border-bottom: 3px solid #fff;
      border-collapse: collapse; 
      background: #fff;
      outline: 3px solid var(--primary-color);
      font-size: 15px;
  }
.container_produtos {
  width: 60%;
  display: grid;
  justify-content: center;
  margin-left: 100px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}


@media (max-width: 1367px) {
  /* .container_destaques {
    width: 70rem;
  } */
}

@media (max-width: 991.98px) {
  .borders {
    margin-right: 2%;
    margin-left: 2%;
  }
 
  
  /* .p {
     width: 90vw; 
  } */
  
  /* .container_destaques {
    width: 85vw;
    display: flex;
    flex-direction: column;
  }

  .container_produtos {
    width: 100%;
    margin: 60px auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  } */
}