.home {
  /* background: url('../../Assets/paw.svg'); */
  background-repeat: no-repeat;
  background-size: 20%;
}

.sclad_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.p {
  width: 90%;
  text-align: center;
  margin: 20px auto
}

.cells {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.toggleswitch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    display: none;
  }

  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    input:checked+& {
      background-color: #2196F3;
    }

    input:focus+& {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked+&:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
}

.borders {
  margin-right: 10%;
  margin-left: 10%;
  border: solid 10px transparent;
  border-radius: 30px;
  background:
    linear-gradient(#ffffff, #fff0cb) padding-box,
    linear-gradient(#ffb700, #feb7c8) border-box;
  align-items: center;

}

.container_marcas {
  /* background: url('../../Assets/paw.svg'); */
  background-repeat: no-repeat;
  background-size: 13%;
  background-position: right;
}

.container_destaques {
  /* background: url('../../Assets/paw.svg'); */
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: left;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}

.sclad_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 15px; */
}

.tableth {
  font-weight: bold;
  padding: 7px;
  background: var(--primary-color);
  border: none;
  text-align: left;
  font-size: 15px;
  border-top: 3px solid #fff;
  border-bottom: 3px solid var(--primary-color);
}

.tabletd {
  padding: 7px;
  border: none;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  font-size: 15px;
}

.table tbody tr:nth-child(even) {
  background: #f8f8f8 !important;
}

.table2 {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  border-radius: 5px;
  background-image: url('../../../pages/auth/unnamed-2.jpg');

}

.table {

  border: 5px solid #fff;
  border-top: 5px solid #fff;
  border-bottom: 3px solid #fff;
  border-collapse: collapse;
  background: #fff;
  outline: 3px solid var(--primary-color);
  font-size: 15px;
}

.container_produtos {
  width: 60%;
  display: grid;
  justify-content: center;
  margin-left: 100px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}


@media (max-width: 1367px) {
  /* .container_destaques {
    width: 70rem;
  } */
}

@media (max-width: 991.98px) {
  .borders {
    margin-right: 2%;
    margin-left: 2%;
  }

  .sclad_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    /* margin: 15px; */
  }

  /* .p {
     width: 90vw; 
  } */
  /* .container_destaques {
    width: 85vw;
    display: flex;
    flex-direction: column;
  }

  .container_produtos {
    width: 100%;
    margin: 60px auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  } */
}