@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-color: #FFA500;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ftr {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  font-family: 'Montserrat', sans-serif;


  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

.bg {
  /* background: linear-gradient( #bbf1ff, #33adff); */
  background-image: url('./unnamed.jpg');
  /* background: url('https://api.new-crm.pupaproj.ru/static/2.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.content {
  position: absolute;
  /* left: 50%;  */
  /* margin-top: 5rem; */
  /* transform: translateX(-50%); */
  width: 100%;
}

.active {
  color: var(--primary-color);
  /* padding: 20px; */
}

.mobile_app {
  display: none;
}

@media (max-width: 991.98px) {
  .mobile_app {
    display: block;
    position: absolute;
    z-index: 999;
  }

  /* .bg {
    background: url('https://api.new-crm.pupaproj.ru/static/1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    height: 100vh;
    width: 100vw;
  } */
}