.container {
    display: flex;
    flex-direction: column;
    /* padding-left: 5%;
    padding-right: 5%; */
    align-items: center;

}

.imglogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    width: 100px;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
}

.search {
    justify-content: center;
    /* margin-top: 20%; */
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: rgb(0, 0, 0);
    align-items: center;
    background-size: contain;
    background-image: url('./unnamed-2.jpg');
    /* background:
      linear-gradient(#ffffff, #fff0cb) padding-box,
      linear-gradient(#ffb700, #feb7c8) border-box; */
    border: 10px solid transparent;
    border-radius: 5px;
    margin-top: 7%;
    /* margin-right: 10%; */
}

@media (max-width: 991.98px) {
    .search {
        margin-top: 20%;
    }
}

/* .container {
    display: flex;
    flex-direction: column;
} */
/* .input{

} */