.home {
    /* background: url('../../Assets/paw.svg'); */
    background-repeat: no-repeat;
    background-size: 20%;
  }
  
  .p {
    width: 90%;
    text-align: center;
    margin: 20px auto
  }
  
  .borders {
    margin-right: 10%;
    margin-left: 10%;
    border: solid 10px transparent;
    border-radius: 30px;
    background:
      linear-gradient(#ffffff, #fff0cb) padding-box,
      linear-gradient(#ffb700, #feb7c8) border-box;
    align-items: center;
  
  }
  .accwindow{
    align-items: center;
    background: linear-gradient(#fff,#fff0cb) padding-box,linear-gradient(#ffb700,#feb7c8) border-box;
    border: 10px solid transparent;
    border-radius: 30px;
    display: flex;
    font-weight: 700;
    min-height: 150px;
    width: 100%;
    justify-content: center;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 35px;
  }
  .container_marcas {
    /* background: url('../../Assets/paw.svg'); */
    background-repeat: no-repeat;
    background-size: 13%;
    background-position: right;
  }
  
  .container_destaques {
    /* background: url('../../Assets/paw.svg'); */
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: left;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
  }
  
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px;
  }
  
  
  @media (max-width: 1367px) {
    /* .container_destaques {
      width: 70rem;
    } */
  }
  
  @media (max-width: 991.98px) {
    .borders {
      margin-right: 2%;
      margin-left: 2%;
    }
    /* .p {
       width: 90vw; 
    } */
    .container_destaques {
      width: 85vw;
      display: flex;
      flex-direction: column;
    }
  
    .container_produtos {
      width: 100%;
      margin: 60px auto;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }