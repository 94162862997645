.modal_container {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    position: fixed;
    /* opacity: 0.7; */
    background-color: rgba(126, 125, 125, 0.527);
    top: 0;
    left: 0;
  }
  .table2{
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    border-radius: 30px;
  }
    .table {
     
        border: 5px solid #fff;
        border-top: 5px solid #fff;
        border-bottom: 3px solid #fff;
        border-collapse: collapse; 
        background: #fff;
        outline: 3px solid var(--primary-color);
        font-size: 15px;
    }
  .tableth {
    font-weight: bold;
    padding: 7px;
    background: var(--primary-color);
    border: none;
    text-align: left;
    font-size: 15px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid var(--primary-color);
}
.tabletd {
    padding: 7px;
    border: none;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    font-size: 15px;
}
.table tbody tr:nth-child(even){
    background: #f8f8f8!important;
}
  .list{
    /* width: 100; */
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;

  }
  .itemlist{
    width: 80%;
  }
  .actionlist{
    width: 20%;
  }
  .cells{
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
  }
  .borders {
    padding: 20px;
    margin-bottom: 20px;
    max-height: 80%;
    overflow-y: auto;
    border: solid 10px transparent;
    border-radius: 30px;
    background:
      linear-gradient(#ffffff, #fff0cb) padding-box,
      linear-gradient(#ffb700, #feb7c8) border-box;
    align-items: center;
  
  }
  .inputtest{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    border: orange solid;
    border-radius: 10px;
    padding: 10px;
  }
  .modal {
    min-width: 30%;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    background: rgb(255, 255, 255);
    /* opacity: 1; */
    padding: 35px;
    border-radius: 10px;
  }
  
  .modal img{
    width: 80%;
    /* height: 100%; */
  }
  
  .container_infos {
    /* width: 300px; */
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    /* flex-wrap: wrap; */
  }
  .title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container_price {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .p {
    font-size: 2rem;
  }
  .price span {
    font-size: 1.1rem;
    color: var(--primary-color);
  }
  .descricao {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  
  .btns {
    display: flex;
    justify-content: center;
    margin: 60px 0;
  }
  
  @media (max-width: 991.98px) {
    .modal {
      align-items: center;
      display: flex;
      flex-direction: column;
      /* width: 90%; */
    }
    .container_infos {
      height: 95%;
      overflow-y: auto;
      display: flex;
      width: 90%;
      flex-direction: row;
      flex-wrap: wrap;
  
  
    }
    .descricao {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      text-align: center;
    }
    .container_price {
      margin-bottom: 20px;
    }
    .title {
      /* display: flex;
      flex-direction: column; */
      font-size: 0.7rem;
      width: 85vw;
      margin: 20px auto;
      text-align: center;
    }
    .p {
      font-size: 1.1rem;
    }
  .modal img {
    margin-top: 10px;
    margin-bottom: 20px;
  
  }
  .btns {
    width: 100%;
    padding: 10px;
    margin: 0;
  }
  }