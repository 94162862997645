.paginationContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    align-items: center;
    list-style-type: none;
}

.paginationContainer .pageItem {

    margin: 0 8px;

    padding: 8px 16px;

    cursor: pointer;

    border: 1px solid #ddd;

    background-color: #f7f7f7;

    color: #337ab7;

    text-decoration: none;

    transition: background-color 0.3s, color 0.3s;

}



.paginationContainer .pageItem:hover,

.paginationContainer .pageItem:focus {

    background-color: #e9e9e9;

    outline: none;

}



.paginationContainer .active {

    border-color: #337ab7;

    background-color: #337ab7;

    color: white;

}



.paginationContainer .disabled {

    color: #cccccc;

    cursor: not-allowed;

    background-color: #f7f7f7;

}



.paginationContainer .disabled:hover {

    background-color: #f7f7f7;

}